import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }

    render(){
        return(
            <>
                <div className="row">
                    {this.props.data.map( (value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                                {value.suffix}
                            </h5>
                            <h5 className="title" style={{fontSize: '1.2rem'}}>{value.countTitle}</h5>
                            <p className="description">{value.countDesc}</p>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}
export default CounterOne;