import React from 'react';
import Accordion01 from "../elements/Accordion";
import { FaqData } from "../data/FaqData";

const FAQs = () => {
    return (
        <div className="rn-about-area ptb--120 bg_color--5">
            <div className="container">
                <div className="text-center service-style--3">
                    <h2 className="az-small-title" style={{marginBottom: 40}} id="faq">FAQs</h2>
                </div>

                <div className="row row--35 align-items-center">
                    <div className="col-lg-6">
                        <div className="thumbnail">
                            <img className="w-100" src="/assets/images/az_images/az_faq.jpg" alt="a-z cleaning faq image"/>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-inner inner">
                                <p className="az-line-height">If your concerns aren't answered below, <a href="mailto:inquiries@azcleancorp.com" style={{color: "#33a908"}}>Email us</a> now and our team will address your tailored inquiries.</p>
                            <div className="accordion-wrapper mt--30">
                                <Accordion01 data={FaqData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FAQs;