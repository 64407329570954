import React from 'react';
import CenteredModal from "./CenteredModal";

const ModalWrapper = (props) => {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <div className="header-btn" style={{paddingTop: "8px"}}>
            <a className="rn-btn" onClick={() => setModalShow(true)}>
                <span style={{cursor: "pointer"}}>Learn More</span>
            </a>

            <CenteredModal title={props.title} text={props.text} show={modalShow} onHide={() => setModalShow(false)} />
        </div>

    )
}

export default ModalWrapper;