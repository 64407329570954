import React from 'react';
import ContactOne from "../../elements/contact/ContactOne";

const ContactForm = () => {
    return (
        <div id="contact" className="fix">
            <div className="rn-contact-area ptb--120 bg_color--1=">
                {/*<h2 className="az-small-title">Submit Contact Info</h2>*/}
                {/*<h3 className="az-small-title" style={{paddingLeft: "18px", paddingBottom: "10px"}}>Submit Contact Info</h3>*/}
                <ContactOne />
            </div>
        </div>
    );
}

export default ContactForm;