export const AboutData = [
    {
        title: "Who We Are",
        text: "A-Z Cleaning Solutions LLC is a pioneer in green commercial cleaning.  In an ever-increasing health" +
            " and environmentally-conscious consumer market, the demand for green cleaning services puts A-Z Cleaning Solutions at the forefront of the competition. As a standard, we utilizes state-of-the-art cleaning equipment, sustainable processes, and non-toxic branded cleaning chemicals."
    },
    {
        title: "What We Do",
        text: "From executive offices, government agencies, corporate headquarters, schools and daycares, our" +
            " goal is to efficiently cover all of your cleaning needs from A to Z. Whether providing a day porter," +
            " pre- and post- construction, move in and move out cleaning, COVID-19 sterilization or scheduled" +
            " commercial" +
            " janitorial services, we are here to eliminate any stress or worry regarding your business."
    },
    {
        title: "Our Mission",
        text: "A-Z will meet and exceed all of your needs and expectations. We do this by listening and working" +
            " closely with your team to insure you’ll always receive a consistent, high-quality cleaning experience. As a minority-owned business, our communities have supported and lifted us up. We believe it is our duty to give back by keeping our community safe and healthy and treating everyone with the upmost respect and professionalism."
    }
]