import React, { Component } from "react";
import { FiX , FiMenu } from "react-icons/fi";

class HeaderFive extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);

        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }

    render() {
        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        
        const { color , headerPosition } = this.props;
        let logoUrl = <img src="/assets/images/logo/az_cleaning_wide2.png" alt="A-Z Cleaning Solutions" />;

        return (
            <header className={`header-area formobile-menu ${headerPosition} ${color}`}>
                <div className="header-wrapper" id="header-wrapper">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-4 col-6">
                                <div className="header-left">
                                    <div className="logo">
                                        <a href="/">
                                            {logoUrl}
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-8 col-6">
                                <div className="header-right justify-content-end">
                                    <nav className="mainmenunav d-lg-block">
                                        <ul className="mainmenu">
                                            <li><a href="#services" onClick={this.CLoseMenuTrigger} style={{fontWeight: "bold"}}>Our Services</a></li>
                                            <li><a href="#about" onClick={this.CLoseMenuTrigger} style={{fontWeight: "bold"}}>About Us</a></li>
                                            <li><a href="#faq" onClick={this.CLoseMenuTrigger} style={{fontWeight: "bold"}}>FAQs</a></li>
                                        </ul>
                                    </nav>

                                    {/*CONTACT BUTTON*/}
                                    <div className="header-btn">
                                        <a className="rn-button-style--2 btn-solid" href="#contact">
                                            <span>Contact Us Now</span>
                                        </a>
                                    </div>

                                    {/* Start Hamburger Menu  */}
                                    <div className="humberger-menu d-block d-lg-none pl--20">
                                        <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                                    </div>
                                    {/* End Hamburger Menu  */}
                                    <div className="close-menu d-block d-lg-none">
                                        <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderFive;