import React from 'react';

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30">
            <p style={{textAlign: "center", lineHeight: 1.4, fontWeight: 600}}>
                <span>&copy; {(new Date().getFullYear())} A-Z Cleaning Solutions.<br/>All Rights Reserved.</span>
            </p>
        </div>
    )
}
export default FooterTwo;