import React from "react";
import {Helmet} from 'react-helmet'

const PageHelmet = (props) => {
    return(
        <>
            <Helmet>
                <title>{props.pageTitle}</title>
                <meta name="description" content="A-Z Cleaning Solutions, a pioneer in green commercial cleaning." />
            </Helmet>
        </>
    )
}

export default PageHelmet;
