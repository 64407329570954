import React from 'react';
import {FiPhone, FiMail, FiMapPin} from "react-icons/fi";
import ContactForm from "./ContactForm";


const ContactInfo = () => {
    return (
        <div className="rn-contact-top-area ptb--120 bg_color--5">
            <div className="container">
                <div className="row">
                    {/* Phone  */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="rn-address">
                            <div className="icon">
                                <FiPhone />
                            </div>
                            <div className="inner">
                                <h4 className="az-tiny-title">Phone</h4>
                                <span>Tel: </span><a href="tel:310-807-1360" className={"az-contactLinks"}>310-807-1360</a>
                                <br />
                                <span>Fax: </span><a href="tel:310-807-1361" className={"az-contactLinks"}>310-807-1361</a>
                            </div>
                        </div>
                    </div>

                    {/* Email  */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                        <div className="rn-address">
                            <div className="icon">
                                <FiMail />
                            </div>
                            <div className="inner">
                                <h4 className="az-tiny-title">Email</h4>
                                <a href="mailto:inquiries@azcleancorp.com" className={"az-contactLinks"}>inquiries@azcleancorp.com</a>
                            </div>
                        </div>
                    </div>

                    {/* Location */}
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                        <div className="rn-address">
                            <div className="icon">
                                <FiMapPin />
                            </div>
                            <div className="inner">
                                <h4 className="az-tiny-title">Location</h4>
                                <p>9350 Wilshire Blvd., Suite 203</p>
                                <p>Beverly Hills, CA 90212</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;