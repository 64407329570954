// docs: https://react-bootstrap.github.io/components/carousel/

import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { CarouselData } from "../data/CarouselData";

export const BootstrapCarousel = () => {
        return (
            <div className="">
                <div className="row justify-content-center">
                    <div className="col-lg-11">
                        <div>
                            <div className='container-fluid' style={{padding: 0}} >
                                <Carousel interval={4000} controls={false} indicators={false} fade={true}>
                                    {CarouselData.data.map((val, i) => (
                                        <Carousel.Item style={{height: "auto"}} key={i} >
                                            <img style={{height: "auto"}}
                                                 className="d-block w-100"
                                                 src={`${CarouselData.imgPath}${val.image}`}
                                                 alt=''
                                            />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default BootstrapCarousel;