export const FaqData = [
    {
        title: "Are your products EPA certified?",
        text: "While there were only around 50 EPA certified products in the beginning of the pandemic, now there are" +
            " a little over 525+ products available. A-Z uses certified products in our day-to-day systems and" +
            " formulas."
    },
    {
        title: "Are you licensed, bonded and insured?",
        text: "Yes, we are licenced, bonded and insured."
    },
    {
        title: "Do we need a COVID-19 sterilization cleaning?",
        text: "While there is no law that requires you to COVID-19 treat your facilities as of now, we recommend you use this service in the event that a confirmed case or cases has been identified. Our deep cleaning services use our high-grade Touchpoint methods that if done in a frequent manner can help you combat any build-up of such bacteria and viruses."
    },
    {
        title: "How often do we need a deep cleaning?",
        text: "Deep cleanings are highly recommended to do 4x a year (every quarter). Depending on the amount of traffic your building receives, we recommend to divide a deep cleaning and rotate it with a COVID-19 sterilization every other month for a safer premises."
    },
    {
        title: "Are your services backed by a satisfaction guarantee?",
        text: "During your first consultation we’ll create a customizable cleaning system plan for your property" +
            " that’s designed to accommodate all of your specific requests. Our cleaning methods ensure the very" +
            " best results. Should you find our services did not meet your expectations, we are happy to rectify the" +
            " situation."
    },
    {
        title: "How much notice is needed if cancellation is required?",
        text: "We know unfortunate events can take place at any given moment. We do our best to work with you and around your schedule. Depending on the job and size we recommend 48-72 hours cancellation notice simply by calling or emailing our team. Minimal fees may apply when cancelling, so please call to discuss."
    },
    {
        title: "What forms of payment do you accept at the time of service?",
        text: "We accept all major credit cards, wire transfers and cash."
    }
]
