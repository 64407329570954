import React from 'react';
import ContactInfo from "./contact-elements/ContactInfo";
import ContactForm from "./contact-elements/ContactForm";

const Contact = () => {
    return (
        <div className="rn-about-area ptb--120 bg_color--5" id="contact">
            <div className="">

                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30">
                            <h2 className="az-small-title">Contact Us Today</h2>
                        </div>
                    </div>
                </div>

                <ContactInfo />

                <div className="row" style={{marginTop: 60}}>
                    <div className="col-lg-12">
                        <div className="section-title text-center service-style--3 mb--30">
                            <h2 className="az-small-title">Submit Contact Info</h2>
                        </div>
                    </div>
                </div>

                <ContactForm />
            </div>
        </div>
    );
}

export default Contact;