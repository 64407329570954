import React, { Component } from "react";

// MODULES
import Helmet from "../component/common/Helmet";
import Header from "../component/header/HeaderFive";

import BootstrapCarousel from '../modules/BootstrapCarousel';
import Services from "../modules/Services";
import About from "../modules/About";
import Counter from "../modules/Counter";
import FAQs from "../modules/FAQs";
import Clients from "../modules/Clients";

import CounterOne from "../elements/counters/CounterOne";
import CounterTwo from "../elements/counters/CounterTwo";
import { CounterData1, CounterData2 } from "../data/CounterData";

import News from "../modules/News";
import Contact from "../modules/Contact";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this);
    }

    openModal() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <>
                <Helmet pageTitle="A-Z Cleaning Solutions"/>
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>

                <div style={{paddingTop: 80}}>
                    <BootstrapCarousel/>
                    <Services/>
                    <About/>
                    <Counter title={"COVID-19 Facts"} component={<CounterOne data={CounterData1}/>}/>
                    <FAQs/>
                    <Counter title={"Interesting Statistics"} component={<CounterTwo data={CounterData2}/>}/>
                    {/*<Clients/>*/}
                    {/*<News/>*/}
                    <Contact/>
                    <CallAction/>
                    <FooterTwo/>
                </div>
            </>
        );
    }
}

export default Home;
