import React from 'react';
import {AboutData} from "../data/AboutData";

const About = () => {
    return (
        <div className="about-area ptb--120 bg_color--5">
            <div className="about-wrapper">
                <div className="container">
                    <div className="text-center service-style--3">
                        <h2 className="az-small-title" id="about">About Us</h2>
                    </div>

                    <div className="row row--35 align-items-center">
                        <div className="col-lg-5">
                            <div className="thumbnail">
                                <img className="w-100" src="/assets/images/az_images/az_about.jpg" alt="a-z cleaning about image"/>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-inner inner" style={{paddingTop: 40}}>
                                {AboutData.map( (val , i) => (
                                    <div key={i}>
                                        <h2 className="az-tiny-title az-about-titles">{val.title}</h2>
                                        <p className="az-text">{val.text}</p>
                                        <span></span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;