import React from 'react';
import { ServicesData } from "../data/ServicesData";

import ModalWrapper from "../component/modal/ModalWrapper";

const Services = () => {
    return (
        <div className="service-area ptb--30 bg_color--1">
            <div className="container">
                <div className="text-center service-style--3">
                    <h2 className="az-small-title" id="services">Our Services</h2>
                </div>

                <div className="row service-one-wrapper">
                    {ServicesData.map( (val , i) => (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" style={{textAlign: 'center'}} key={i}>
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="az-tiny-title">{val.title}</h3>
                                        <p className="az-line-height">{val.description}</p>
                                    </div>
                                    <ModalWrapper title={val.modalTitle} text={val.modalText}/>
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Services;