import React from 'react';

const Counter = (props) => {
    return (
        <div className="rn-counterup-area pt--140 p bg_color--5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title service-style--3 text-center">
                            <h2 className="az-small-title">{props.title}</h2>
                        </div>
                    </div>
                </div>
                {props.component}
            </div>
        </div>
    );
};

export default Counter;