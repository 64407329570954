export const CounterData1 = [
    {
        countNum : 527,
        countTitle: 'As of 2021 there are now over 527 verifiable solutions that combat COVID-19 (SARS-CoV-2).',
        countDesc: '',
        suffix: '+'
    },
    {
        countNum : 94,
        countTitle: '94% of the population must be immune to interrupt the chain of transmission and spread of the virus.',
        countDesc: '',
        suffix: '%'
    },
    {
        countNum : 4,
        countTitle: 'An asymptomatic employee can infect 50% of office equipment and coworkers in just 4 hours.',
        countDesc: '',
        suffix: ' Hrs'
    }
]

export const CounterData2 = [
    {
        countNum : 1000,
        countTitle: 'About 20% of people dry their hands after washing. Damp hands are 1,000 times more likely to spread bacteria than dry hands.',
        countDesc: '',
        suffix: "x"
    },
    {
        countNum :  301,
        countTitle: 'There are over 300,000 germs living on your devices. Studies show using anti-microbial wipes daily won’t degrade protective coatings on your phone.',
        countDesc: '',
        suffix: 'k'
    },
    {
        countNum : 16,
        countTitle: 'We tend to touch our face 16x per hour. Our eyes, nose, and mouth are our most vulnerable entry ways to harmful bacteria and viruses.',
        countDesc: '',
        suffix: 'x'
    },
    {
        countNum : 100,
        countTitle: 'Germs and other particles ejected by sneezing travel at speeds of up to 100 mph, spread up to 100,000 droplets, spread 27 feet, and can live on surfaces for 45 minutes.',
        countDesc: '',
        suffix: 'mph'
    }
]