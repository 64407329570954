export const CarouselData = {
    imgPath: "../assets/images/carousel/",
    data: [
        {image: "carousel_01.jpg", caption: "Test"},
        {image: "carousel_02.jpg", caption: "Test"},
        {image: "carousel_03.jpg", caption: "Test"},
        {image: "carousel_04.jpg", caption: "Test"},
        {image: "carousel_05.jpg", caption: "Test"},
        {image: "carousel_06.jpg", caption: "Test"},
    ]
}
