import React, { Component } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import ScrollAnimation from 'react-animate-on-scroll';

class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }

    render(){
        return(
            <>
                <div className="row">
                    {this.props.data.map( (value, index) => (
                        // <div className="counterup_style--1 col-lg-3 col-md-6 col-6" key={index}>
                        <div className="counterup_style--1 col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                            {/*<ScrollAnimation */}
                            {/*    animateIn="fadeInUp" */}
                            {/*    animateOnce={true} */}
                            {/*    delay={100}*/}
                            {/*>*/}
                            {/*    <h5 className="counter" style={{fontSize: 67}}>*/}
                                <h5 className="counter">
                                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                        <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                    </VisibilitySensor>
                                    {value.suffix}
                                </h5>
                                <h5 className="title" style={{fontSize: '1.2rem', padding: '16px 7px'}}>{value.countTitle}</h5>
                                <p className="description">{value.countDesc}</p>
                            {/*</ScrollAnimation>*/}
                        </div>
                    ))}
                </div>
            </>
        )
    }
}
export default CounterOne;