import React from 'react';
import {RiCommunityLine, RiSurgicalMaskLine} from "react-icons/ri";
import {GiSpray, GiVacuumCleaner} from "react-icons/gi";
import {HiOutlineSparkles} from "react-icons/hi";
import {BsPeople} from "react-icons/bs";

export const ServicesData = [
    {
        icon: <RiSurgicalMaskLine/>,
        title: "COVID-19 Sterilization",
        description: "Our proprietary touchpoint and fogging system kills the virus and ensures the safety and" +
            " well-being of your employees.",
        modalTitle: "COVID-19 Cleaning and Sanitation ",
        modalText: "At A-Z Cleaning Solutions, we have a two-phase system of COVID cleaning: touchpoint and fogging. Our two-phase system of touchpoint and fogging is the most comprehensive method for deep disinfection. Touchpoint focuses disinfecting on all the areas most used and, therefore, at the highest risk during this worldwide epidemic. Foggers are potent tools for complete deep cleaning and disinfecting from COVID. Fog disinfecting covers the entire room and will help control the virus in areas that are not considered the vital touchpoints."
    },

    {
        icon: <GiSpray/>,
        title: "Commercial Cleaning",
        description: "From banks, offices, and industrial sectors, our team will provide premium commercial-grade janitorial services.",
        modalTitle: "Commercial Janitorial Cleaning Services",
        modalText: "From banks to office buildings to warehouses, at A-Z Cleaning Solutions, we know you need your time and energy to focus on your business, and you likely do not have the time to clean your property or take care of maintenance yourself. At A-Z Cleaning Solutions, we provide the professionalism and expertise your business needs to take care of all your janitorial services. This includes everything from waste removal and office cleaning to window washing."
    },

    {
        icon: <HiOutlineSparkles className="az-icon"/>,
        title: "Deep Cleaning Services",
        description: "We are committed to providing a sterile atmosphere for facilities to ensure a sustainable," +
            " safe, and secure environment.",
        modalTitle: "Deep Cleaning Services",
        modalText: "A clean and well-maintained exterior & interior creates an inviting atmosphere. It also projects an impression of stability and efficiency, which are qualities that attract visitors, potential customers, residents or investors to your property. It is essential to maintain regular cleaning schedules for your properties interior and exterior. One of the best ways to do this is by hiring A-Z Cleaning Solutions to provide our reliable, dependable, and trusted Steam Cleaning services for heavy duty jobs or soft and gentle ones. Our steamers reach the highest industry leading temperatures and pressure settings which will allow cleaning and disinfecting of a variety of delicate objects. We can also steam clean your Carpets.Carpets are a breeding ground for germs and allergens that can put your employees or tenants at risk of getting inflicted with several health hazards."
    },

    {
        icon: <GiVacuumCleaner/>,
        title: "100% Chemical Free",
        description: "By killing more than 99% of viruses, germs, and bacteria, see why hospitals, clinics and nursing homes choose us first.",
        modalTitle: "100% Chemical Free Steam and Pressure Washing",
        modalText: "A clean and well-maintained exterior & interior creates an inviting atmosphere. It also projects an impression of stability and efficiency, which are qualities that attract visitors, potential customers, residents or investors to your property. It is essential to maintain regular cleaning schedules for your properties interior and exterior. One of the best ways to do this is by hiring A-Z Cleaning Solutions to provide our reliable, dependable, and trusted Steam Cleaning services for heavy duty jobs or soft and gentle ones. Our steamers reach the highest industry leading temperatures and pressure settings which will allow cleaning and disinfecting of a variety of delicate objects. We can also steam clean your Carpets.Carpets are a breeding ground for germs and allergens that can put your employees or tenants at risk of getting inflicted with several health hazards."
    },

    {
        icon: <RiCommunityLine/>,
        title: "Pre/Post Construction",
        description: "We offer comprehensive cleaning for all construction and remodelling projects. Your project will be inspection ready!",
        modalTitle: "Pre/Post Construction Services",
        modalText: "We offer pre- and post- construction clean up services for all types of commercial and residential properties. We understand that every project is different, but each project can leave a great deal of detritus and dust behind. A-Z Cleaning Solutions understands that you have invested so much time, money and effort in your remodeling or building your new residential or commercial space, and we are aware that you will want your final presentation to be clean and beautiful. We guarantee to accommodate the needs of our clients accordingly. We can also provide final cleaning for inspection or touch up cleaning."
    },

    {
        icon: <BsPeople/>,
        title: "Moving Solutions",
        description: "We're pleased to provide moving in/out cleaning services to commercial and residential property owners and landlords.",
        modalTitle: "Commercial and Residential Move In/Out Cleaning",
        modalText: "A-Z Cleaning Solutions is pleased to provide move-in cleaning and move-out cleaning services to commercial and residential property owners and landlords. Although we usually only provide cleaning services for commercial buildings, we make an exception for move-in and move-out cleaning.  We will provide this service for homes, apartments, condos, townhouses, and any other type of residence.  If the residence in question does not contain any furniture and all the belongings of the occupants have been removed, we can provide a thorough top-to-bottom cleaning."
    }
]
