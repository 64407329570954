import React, { Component } from "react";
import emailjs from 'emailjs-com';

class ContactOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_name: '',
            user_email: '',
            user_phone: '',
            message: ''
        };

        emailjs.init('user_GzlbHl3j8ZILgs5VxC2kj');
    }

    sendEmail = (e) => {
        e.preventDefault();
        const service_id = 'service_xczk8r2';
        const template_id = 'template_67d9dt8';

        emailjs.sendForm (service_id, template_id, e.target)
            .then((result) => {
                console.log (result.text);
            }, (error) => {
                console.log (error.text);
            });

        this.setState({user_name: '', user_email: '', user_phone: '', message: ''});
    }

    handleFields = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="form-wrapper">
                                <form className="contact-form" onSubmit={this.sendEmail}>
                                    <label>Name *</label>
                                    <input type="text" required name="user_name" value={this.state.user_name} onChange={this.handleFields} />
                                    <label>Email *</label>
                                    <input type="email" required name="user_email" value={this.state.user_email} onChange={this.handleFields} />
                                    <label>Phone *</label>
                                    <input type="tel" required name="user_phone" value={this.state.user_phone} onChange={this.handleFields} />
                                    <label>Message *</label>
                                    <textarea name="message" required value={this.state.message} onChange={this.handleFields} />
                                    <button className="rn-button-style--2 btn-solid submit-button" type="submit" value="Send">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/az_images/az_contact.jpg" alt="a-z cleaning contact image"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;