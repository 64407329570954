import React, {Component} from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

const Accordion01 = (props) => {
        return (
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                {props.data.map((val, i) => (
                    <AccordionItem key={i}>
                        <AccordionItemHeading>
                            <AccordionItemButton style={{color:'#263c78'}}>
                                {val.title}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p className="az-line-height">
                                {val.text}
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                ))}
            </Accordion>
        )
}

export default Accordion01;





